import TableResult from "components/common/tableResult";
import { Box, Button, IconButton, Input, Toolbar, Tooltip } from "@mui/material";
import { ColumnOption } from "model/ColumnOption";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import * as Actions from "../../store/actions/userActions";
import { ClipLoader } from "react-spinners";
import NewUserDrawer from "components/users/newUserDrawer";
import { Check, FileDownload, FileDownloadOutlined, FileUpload, FileUploadOutlined, PersonAddRounded, RemoveCircleOutline, Search } from "@mui/icons-material";
import useWindowDimensions from "services/windowDimensions";
import UserSearchDrawer from "./userSearchDrawer";
import UserImportDrawer from "./userImportDrawer";
import WaitingComponent from "components/common/waitingComponent";


function UserTab({ permissions, errorMessage, actionPending, searchPermissions, exportPermissions, postPermission, uploadPermission }) {


    const columOptions =
        [
            new ColumnOption("", "isActive", "p.is_active", (item) => {
                if (item.isActive)
                    return (
                        <IconButton sx={{ padding: 0 }} onClick={() => {
                            item.isActive = false;
                            postPermission(item);
                        }}>
                            <Check sx={{ fontSize: 16 }} />
                        </IconButton>
                    );
                else
                    return (
                        <IconButton sx={{ padding: 0 }} onClick={() => {
                            item.isActive = true;
                            postPermission(item);
                        }}>
                            <RemoveCircleOutline sx={{ fontSize: 16 }} />
                        </IconButton>
                    );
            }),
            new ColumnOption("Id", "id", "p.id"),
            new ColumnOption("Email", "email", "p.user_id"),
            new ColumnOption("LastName", "lastName", "u.last_name"),
            new ColumnOption("FirstName", "firstName", "u.first_name"),
            new ColumnOption("Role", "roleId", "p.role_id"),
            new ColumnOption("Subsidiary", "sj", "p.legal_corporation_id"),
            new ColumnOption("Customer", "customerMdmId", "p.client_company_id"),
        ];

    var dim = useWindowDimensions();
    var pageSize = 10;
    if (dim.height > 900)
        pageSize = 15;

    const [pagingParameter, setPagingParameter] = useState({
        currentPage: 0,
        pageSize: pageSize,
        currentSortId: 'p.id',
        isAsc: false
    });
    const [filter, setFilter] = useState(null);
    const [criterias, setCriterias] = useState({});
    const [showDrawerAdd, setShowDrawerAdd] = useState(false);
    const [showDrawerImport, setShowDrawerImport] = useState(false);
    const [showDrawerSearch, setShowDrawerSearch] = useState(false);
    const [workMessage, setWorkMessage] = useState("Loading");

    useEffect(() => { searchPermissions(pagingParameter, criterias, pageSize); }, []);

    function submitSearchForm(event) {
        event.preventDefault();
        setPagingParameter({
            currentPage: 0,
            pageSize: pageSize,
            currentSortId: 'p.id',
            isAsc: false
        });
        searchPermissions({
            currentPage: 0,
            pageSize: pageSize,
            currentSortId: 'p.id',
            isAsc: false
        }, criterias, pageSize);
    }

    function pageChangingHandler(event, newPagingParameter) {
        setPagingParameter(newPagingParameter);
        searchPermissions(newPagingParameter, criterias, pageSize);
    }

    function validatePopup() {
        setShowDrawerAdd(false);
    }

    function searchFromDrawer(criteriasFromDrawer) {
        setShowDrawerSearch(false);
        setCriterias(criteriasFromDrawer);
        searchPermissions({
            currentPage: 0,
            pageSize: pageSize,
            currentSortId: 'p.id',
            isAsc: false
        }, criteriasFromDrawer, pageSize);
        setPagingParameter({
            currentPage: 0,
            pageSize: pageSize,
            currentSortId: 'p.id',
            isAsc: false
        });
    }

    function drawerNewUser() {
        return (
            <NewUserDrawer showDrawer={showDrawerAdd} onClose={() => setShowDrawerAdd(false)} onValidate={validatePopup}></NewUserDrawer>
        );
    }


    function drawerImportUser() {
        return (
            <UserImportDrawer
                showDrawer={showDrawerImport}
                onClose={() => setShowDrawerImport(false)}
                onValidate={uploadFromDrawer}>
            </UserImportDrawer>
        );
    }

    function drawerSearchSite() {
        return (
            <UserSearchDrawer
                showDrawer={showDrawerSearch}
                inputCriterias={criterias}
                onClose={(c) => setShowDrawerSearch(false)}
                onValidate={(criterias) => searchFromDrawer(criterias)}></UserSearchDrawer>
        );
    }


    function uploadFromDrawer(file) {
        setShowDrawerImport(false);
        uploadPermission(file);
    }

    function mainContent() {
        if (!actionPending)
            return (
                <>
                    <Toolbar>
                        <form onSubmit={submitSearchForm}>
                            <Input
                                value={criterias.freeSearch}
                                onChange={(e) => { setCriterias({ freeSearch: e.target.value }) }}
                            />
                            <Tooltip title="Free search" placement="top">
                                <IconButton
                                    onClick={submitSearchForm}>
                                    <Search />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Exporter" placement="top">
                                <IconButton
                                    onClick={() => exportPermissions(criterias)}>
                                    <FileDownload />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Importer" placement="top">
                                <IconButton
                                    onClick={() => setShowDrawerImport(true)}>
                                    <FileUpload />
                                </IconButton>
                            </Tooltip>
                        </form>
                        <Box
                            sx={{ flexGrow: 1 }}></Box>
                        <Tooltip title="Add a new user" placement="top">
                            <Button
                                onClick={(e) => {
                                    setShowDrawerAdd(true);
                                    e.stopPropagation();
                                }}
                                sx={{ marginLeft: '5px' }}
                                variant="outlined"
                                startIcon={<PersonAddRounded />}
                            >
                                Add
                            </Button>
                        </Tooltip>
                        <Tooltip title="Extended search" placement="top">
                            <Button
                                variant="outlined"
                                sx={{ marginLeft: '5px' }}
                                startIcon={<Search />}
                                onClick={() => setShowDrawerSearch(true)}
                            >
                                Extended search
                            </Button>
                        </Tooltip>
                    </Toolbar>
                    <TableResult headers={columOptions}
                        items={permissions}
                        pagingParam={pagingParameter}
                        onPageChange={pageChangingHandler} />
                </>
            );
        else
            return (
                <WaitingComponent message={workMessage}/>
            );
    }

    return (
        <div onClick={() => setShowDrawerAdd(false)}>
            {mainContent()}
            {drawerNewUser()}
            {drawerImportUser()}
            {drawerSearchSite()}
        </div>
    );
}


const mapStateToProps = (state) => {
    return {
        permissions: state.yellowstoneReducer.entities,
        errorMessage: state.yellowstoneReducer.errorMessage,
        actionPending: state.yellowstoneReducer.actionPending,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        searchPermissions: (startPage, criterias, count) => {
            dispatch(Actions.GetPermissions(startPage, criterias, count));
        },
        postPermission: (permssion) => {
            dispatch(Actions.PostPermissions(permssion));
        },
        exportPermissions: (criterias) => {
            dispatch(Actions.ExportPermissions(criterias));
        },
        uploadPermission: (file) => {
            dispatch(Actions.PostFile(file));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserTab);