import * as ApiActions from "./apiCaller";
import { config } from "config";
import axios from "axios";
export function GetAll(handleLoadComplete, handleError) {
    var baseUrlForRoles = config.REACT_APP_BASE_API_URL + "MasterDatas/Roles";
    var baseUrlForSjs = config.REACT_APP_BASE_API_URL + "MasterDatas/Sjs";
     // var baseUrlForUes = config.REACT_APP_BASE_API_URL + "MasterDatas/Ues";
    //  var baseUrlForUps = config.REACT_APP_BASE_API_URL + "MasterDatas/Ups";

    axios.all([ApiActions.getAxiosrequest(baseUrlForRoles),
    ApiActions.getAxiosrequest(baseUrlForSjs),
       // ApiActions.getAxiosrequest(baseUrlForUes),
        // ApiActions.getAxiosrequest(baseUrlForUps)
    ]).then(axios.spread((...responses) => {
        const roles = responses[0].data;
        const sjs = responses[1].data;
       // const ues = responses[2].data;
        // const ups = responses[3].data;
        const portalSjs = [
            { id: '170', label: '(170) DELTA' },
            { id: '110', label: '(110) BARRETT' }
        ];
        localStorage.setItem("roles", JSON.stringify(roles));
        localStorage.setItem("portalSjs", JSON.stringify(portalSjs));
        localStorage.setItem("sjs", JSON.stringify(sjs));
        // localStorage.setItem("ues", JSON.stringify(ues));
        // localStorage.setItem("ups", JSON.stringify(ups));
        handleLoadComplete();
        // use/access the results 
    })).catch(errors => {
        handleError(errors);
    })
}