
import { IconButton, Typography } from "@mui/material";
import { Add, BrokenImage, BrokenImageOutlined, } from "@mui/icons-material";
import 'style/CustomerPart.scss';
import { useEffect, useState } from "react";
import { NewUserDialog } from "components/users/newUserDialog";

function CustomerAccountPart({ accounts, customerId }) {

    const [showAddDialog, setShowAddDialog] = useState(false);


    var header = <div className="titleContainer">
        <div className="divTampon" />
        <div className="divTitle">
             Accounts
        </div>
        <div className="divTampon" />
        <div className="addButton">
            <IconButton onClick={() => setShowAddDialog(true)}>
                <Add />
            </IconButton>
        </div>
    </div>;

    function handleNewUserClose() {
        setShowAddDialog(false);
    }

    function BuildList() {
        if (accounts === null
            || accounts === undefined
            || accounts.length === 0) {
            return (
                <div className="dataDetailContainerCenterAlign">
                    <div className="noContent">
                        No linked account
                    </ div>
                </div>
            );
        }
        else {
            return (
                <div className="dataDetailContainer">
                    <table className="tableData">
                        <thead>
                            <th>
                                Action
                            </th>
                            <th>
                                Id
                            </th>
                            <th>
                                Sj
                            </th>
                            <th>
                                Email
                            </th>
                            <th>
                                First name
                            </th>
                            <th>
                                Last Name
                            </th>
                        </thead>
                        <tbody>
                            {
                                accounts.map(item =>
                                    <tr>
                                        <td></td>
                                        <td>{item.id}</td>
                                        <td>{item.sj}</td>
                                        <td>{item.email}</td>
                                        <td>{item.firstName}</td>
                                        <td>{item.lastName}</td>
                                    </tr>)
                            }
                        </tbody>
                    </table>
                </div>
            );
        }
    }

    return (
        <div className="container">
            {header}
            {BuildList()}
            <NewUserDialog open={showAddDialog}
                handleClose={handleNewUserClose}
                permissionParam={{ clientId: customerId }} />
        </div>
    );
}

export default CustomerAccountPart;