import * as ApiActions from "./apiCaller";
import { config } from "config";
import axios from "axios";

export function PostPermissions(permission, successHandler, errorHandler) {

    var baseUrl = config.REACT_APP_BASE_API_URL + 'Users';
    ApiActions.postAPI(baseUrl, JSON.stringify(permission))
        .then((datas) => {
            if (successHandler != null)
                successHandler();
        })
        .catch((error) => {
            var errorMessage = "An error has occurred.";
            if (error.response.status == 400)
                errorMessage = "The data is invalid";
            if (errorHandler != null)
                errorHandler(errorMessage);
            console.error(error);
        });
}