import * as Types from "../types/yellowstoneTypes";
import * as YellowstoneTypes from "../reducers/yellowstoneReducer";
import * as ApiActions from "../../services/apiCaller";
import Download from "js-file-download";
import { config } from "config";
import { Permission } from "model/Permission";

export function GetPermissions(pagingParameter: any, searchCriterias: any, count: number) {
    return (dispatch: any) => {
        dispatch({
            type: Types.BEGIN_ACTION
        });

        var isAsc = "true";
        if (!pagingParameter.isAsc)
            isAsc = "false";
        var baseUrl = config.REACT_APP_BASE_API_URL
            + 'Users?StartPage=' + pagingParameter.currentPage
            + '&Count=' + pagingParameter.pageSize
            + '&OrderField=' + pagingParameter.currentSortId
            + '&IsAsc=' + pagingParameter.isAsc;
        if (searchCriterias !== null) {
            if (searchCriterias.freeSearch != null
                && searchCriterias.freeSearch != undefined)
                baseUrl += '&freeSearch=' + searchCriterias.freeSearch;
            if (searchCriterias.firstName != null
                && searchCriterias.firstName != undefined)
                baseUrl += '&FirstName=' + searchCriterias.firstName;
            if (searchCriterias.lastName != null
                && searchCriterias.lastName != undefined)
                baseUrl += '&LastName=' + searchCriterias.lastName;
            if (searchCriterias.clientId != null
                && searchCriterias.clientId != undefined)
                baseUrl += '&ClientId=' + searchCriterias.clientId;
            if (searchCriterias.email != null
                && searchCriterias.email != undefined)
                baseUrl += '&Email=' + searchCriterias.email;
            if (searchCriterias.sjId != null
                && searchCriterias.sjId != undefined)
                baseUrl += '&Sj=' + searchCriterias.sjId;
        }

        ApiActions.getAPI(baseUrl)
            .then((datas) => {
                dispatch({
                    type: Types.END_ACTION,
                    typeEntity: YellowstoneTypes.TypeEntity.Permission,
                    typeAction: YellowstoneTypes.TypeAction.GetList,
                    entities: datas.data
                });
            })
            .catch((error) => {
                var errorMessage = "An error has occurred.";
                dispatch({
                    type: Types.END_ACTION,
                    entities: [],
                    errorMessage: errorMessage
                });
                console.error(error);
            });
    };
}

export function ExportPermissions(searchCriterias: any) {
    return (dispatch: any) => {

        var baseUrl = config.REACT_APP_BASE_API_URL
            + 'Users/Export?';
        if (searchCriterias !== null) {
            if (searchCriterias.firstName != null
                && searchCriterias.firstName != undefined)
                baseUrl += '&FirstName=' + searchCriterias.firstName;
            if (searchCriterias.lastName != null
                && searchCriterias.lastName != undefined)
                baseUrl += '&LastName=' + searchCriterias.lastName;
            if (searchCriterias.email != null
                && searchCriterias.email != undefined)
                baseUrl += '&Email=' + searchCriterias.email;
            if (searchCriterias.sjId != null
                && searchCriterias.sjId != undefined)
                baseUrl += '&Sj=' + searchCriterias.sjId;
        }

        ApiActions.downloadAPI(baseUrl)
            .then((datas) => {
                Download(datas.data, 'export_users.csv');

            })
            .catch((error) => {
                dispatch({
                    type: Types.END_ACTION,
                    entities: [],
                    errorMessage: error
                });
                console.error(error);
            });
    };
}

export function PostPermissions(permission: Permission, successHandler: any, errorHandler: any) {
    return (dispatch: any) => {
        dispatch({
            type: Types.BEGIN_ACTION
        });

        var baseUrl = config.REACT_APP_BASE_API_URL + 'Users';
        ApiActions.postAPI(baseUrl, JSON.stringify(permission))
            .then((datas) => {
                if (successHandler != null)
                    successHandler();
                dispatch(
                    GetPermissions(
                        {
                            currentPage: 0,
                            pageSize: 10,
                            currentSortId: 'p.id',
                            isAsc: false
                        },
                        '',
                        10));
            })
            .catch((error) => {
                var errorMessage = "An error has occurred.";
                if (error.response.status == 400)
                    errorMessage = "The data is invalid";
                if (errorHandler != null)
                    errorHandler(errorMessage);
                dispatch({
                    type: Types.END_ACTION_WITHERROR,
                    errorMessage: errorMessage
                });
                console.error(error);
            });
    };
}

export function PostFile(file: any) {
    return (dispatch: any) => {

        dispatch({
            type: Types.BEGIN_ACTION
        });

        var baseUrl = config.REACT_APP_BASE_API_URL
            + 'Users/Upload';


        ApiActions.postFileAPI(baseUrl, file)
            .then((datas) => {
                dispatch(
                    GetPermissions(
                        {
                            currentPage: 0,
                            pageSize: 10,
                            currentSortId: 'p.id',
                            isAsc: false
                        },
                        '',
                        10));
            })
            .catch((error) => {
                dispatch({
                    type: Types.END_ACTION,
                    entities: [],
                    errorMessage: error
                });
                console.error(error);
            });
    };
}