import { makeStyles, TextField } from "@material-ui/core";
import { connect, useDispatch } from "react-redux";
import { Label } from "@mui/icons-material";
import { Autocomplete, Box, Button, CssBaseline, Drawer, Input, Typography } from "@mui/material";
import { textAlign } from "@mui/system";
import { useEffect, useState } from "react";
import { isTemplateExpression } from "typescript";
import * as Actions from "../../store/actions/userActions";

function NewUserDrawer({ sjs, roles, showDrawer, onClose, onValidate, postPermission }) {

    const textFieldForDrawer = makeStyles({
        textField: {
            width: "90%",
            marginLeft: "5px",
            marginRight: "auto",
            paddingBottom: 0,
            marginTop: "20px",
            fontWeight: 500,
            "& label.Mui": {
                color: "#FFFFFF"
            },
            "& label.Mui &::placeholder": {
                color: "#FFFFFF"
            },
            "& label.Mui-focused": {
                color: "#FFFFFF"
            },
            // focused color for input with variant='standard'
            "& .MuiInput-underline:after": {
                borderBottomColor: "#FFFFFF"
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: '#FFFFFF',
                },
                '&:hover fieldset': {
                    borderColor: '#FFFFFF',
                },
                '&.Mui-focused fieldset': {
                    borderColor: '#FFFFFF',
                },
            },
        },
        input: {
            color: "#FFFFFF"
        }
    });
    const [permission, setPermission] = useState({});
    const [errorMessage, setErrorMessage] = useState(null);

    const classes = textFieldForDrawer();

    const autoCompleteStyle = {
        width: "100%",
        marginRight: "auto",
        paddingBottom: 0,
        fontWeight: 500,
        '& input': {
            color: "white"
        },
        '& label': {
            color: "white"
        },
        "& label.Mui &::placeholder": {
            color: "#FFFFFF"
        },
        "& label.Mui-focused": {
            color: "#FFFFFF"
        },
        // focused color for input with variant='standard'
        "& .MuiInput-underline:after": {
            borderBottomColor: "#FFFFFF",
            transform: "none"
        },
        "& .MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popuIndicator": {
            color: "#FFFFFF"
        }

    };

    useEffect(() => {
        setPermission({});
        setErrorMessage(null);
    }, [showDrawer]);

    function getSjOptions() {
        let sjs = JSON.parse(localStorage.getItem("sjs"));
        sjs = sjs.filter(sj => sj.isOnboarded);
        const options = sjs.map((sj) => {
            return {
                "label": `(${sj.id}) ${sj.name}`,
                "id": sj.id
            };
        });
        return options;
    }
    function getRoleOptions() {
        var roles = JSON.parse(localStorage.getItem("roles"));
        const options = roles.map((role) => {
            return {
                "label": role.label,
                "id": role.id
            };
        });
        return options;
    }
    function getSelectedRoleOption() {
        var roles = JSON.parse(localStorage.getItem("roles"));
        var currentRole = roles.find((item) => item.id == permission.roleId);
        if (currentRole != null && currentRole != undefined)
            return {
                "label": currentRole.label,
                "id": currentRole.id
            };
        else
            return null;
    }
    function getSelectedSjOption() {
        const options = getSjOptions();
        var currentSj = options.find((item) => item.id == permission.sjId);
        if (currentSj != null && currentSj != undefined)
            return {
                "label": currentSj.label,
                "id": currentSj.id
            };
        else
            return null;
    }

    function updatePermission(e, property) {
        if (e.target !== undefined)
            permission[property] = e.target.value;
        else
            permission[property] = e;
        setPermission(permission);
    }

    function sendPermission() {
        postPermission(permission,
            () => {
                setErrorMessage(null);
                setPermission({});
                onValidate();
            },
            (error) => { setErrorMessage(error) }
        );
    }

    var errorPart = (
        <></>
    );
    if (errorMessage != null) {
        errorPart = (
            <div style={{ width: '90%', textAlign: 'center', marginTop: '40px', color: 'red', backgroundColor: '#0D5C63' }}>
                {errorMessage}
            </div>
        );
    }

    return (
        <Drawer
            anchor="right"
            open={showDrawer}
            onClose={onClose}
            onClick={(e) => e.stopPropagation()}
        >
            <Box
                component="form"
                width="40vh"
                sx={{ flexGrow: 1 }}
            >
                <div style={{ width: '90%', margin: '10px' }}>
                    <Typography
                        sx={{ color: "#FFFFFF", fontWeight: "900" }}
                    >
                        Add User
                    </Typography>
                </div>
                <div style={{}}>
                    <CssBaseline />
                    <TextField
                        required
                        label="Email"
                        variant="outlined"
                        value={permission.userId}
                        onChange={(e) => updatePermission(e, "userId")}
                        className={classes.textField}
                        InputProps={{
                            className: classes.input
                        }}
                        InputLabelProps={{
                            className: classes.input
                        }}
                    />
                    <Autocomplete
                        id="ac-sj"
                        disablePortal
                        variant="outlined"
                        onChange={(e, value) => {
                            updatePermission(value.id, "sjId")
                        }}
                        options={getSjOptions()}
                        defaultValue={getSelectedSjOption()}
                        sx={autoCompleteStyle}
                        renderInput={(params) => <TextField
                            required
                            variant="outlined"
                            className={classes.textField}
                            InputProps={{
                                className: classes.input
                            }}
                            InputLabelProps={{
                                className: classes.input
                            }}
                            {...params} label="Subsidiary #" />}
                    />
                    <Autocomplete
                        id="ac-role"
                        disablePortal
                        onChange={(e, value) => {
                            updatePermission(value.id, "roleId")
                        }}
                        options={getRoleOptions()}
                        defaultValue={getSelectedRoleOption()}
                        sx={autoCompleteStyle}
                        renderInput={(params) => <TextField
                            required
                            variant="outlined"
                            className={classes.textField}
                            InputProps={{
                                className: classes.input
                            }}
                            InputLabelProps={{
                                className: classes.input
                            }}
                            {...params} label="Role" />}
                    />
                    <TextField
                        label="Client Id"
                        variant="outlined"
                        value={permission.clientId}
                        onChange={(e) => updatePermission(e, "clientId")}
                        className={classes.textField}
                        InputProps={{
                            className: classes.input
                        }}
                        InputLabelProps={{
                            className: classes.input
                        }} />
                </div>
                <div style={{ width: '90%', textAlign: 'center', marginTop: '40px' }}>
                    <Button
                        onClick={sendPermission}
                        variant='outlined'
                        sx={{ color: "white", borderColor: "white" }}
                    >
                        To validate
                    </Button>
                </div>
                {errorPart}
            </Box>
        </Drawer>
    );
}

const mapStateToProps = (state) => {
    return {
        sjs: state.masterDataReducer.sjs,
        roles: state.masterDataReducer.roles,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        postPermission: (permssion, successHansler, errorHandler) => {
            dispatch(Actions.PostPermissions(permssion, successHansler, errorHandler));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewUserDrawer);
