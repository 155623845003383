import { createTheme, TableCell, Typography } from "@mui/material";
import { withStyles, makeStyles } from '@mui/styles';
import { grey } from "@mui/material/colors";

export const theme = createTheme({
  palette: {
    primary: {
      main: '#000',
    },
    secondary: {
      main: "#0D5C63",
    },
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#0D5C63"
        }
      }
    }
  }
});

export const TableHeaderCell = withStyles(theme => ({
  root: {
    backgroundColor: "#0D5C63",
    color: "#FFFFFF",
    fontWeight: "700"
  }
}))(TableCell);

export const SelectedLink = withStyles(theme => ({
  root: {
    fontWeight: "700"
  }
}))(Typography);


export const textFieldForDrawer = makeStyles({
  textField: {
      width: "90%",
      marginLeft: "5px",
      marginRight: "auto",
      paddingBottom: 0,
      marginTop: "20px",
      fontWeight: 500,
      "& label.Mui": {
          color: "#FFFFFF"
      },
      "& label.Mui &::placeholder": {
          color: "#FFFFFF"
      },
      "& label.Mui-focused": {
          color: "#FFFFFF"
      },
      // focused color for input with variant='standard'
      "& .MuiInput-underline:after": {
          borderBottomColor: "#FFFFFF"
      },
      '& .MuiOutlinedInput-root': {
          '& fieldset': {
              borderColor: '#FFFFFF',
          },
          '&:hover fieldset': {
              borderColor: '#FFFFFF',
          },
          '&.Mui-focused fieldset': {
              borderColor: '#FFFFFF',
          },
      },
  },
  input: {
      color: "#FFFFFF"
  }
});



