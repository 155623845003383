import { useEffect, useState, useRef, useCallback } from "react";
import useFetch from "../../hooks/useFetch";
import { connect, useDispatch } from "react-redux";
import * as Actions from "../../store/actions/customerActions";
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Button } from "@mui/material";
import WaitingComponent from "./waitingComponent";
import { ClipLoader } from "react-spinners";

function TableInfinite({ uriBuilder, paging, crit, displayHeader, displayLine }) {

    const [pagingParameter, setPagingParameter] = useState(paging);
    const [page, setPage] = useState(0);
    const [uri, SetUri] = useState(uriBuilder(paging, crit))
    const { loading, error, list } = useFetch(uri, crit);
    const loader = useRef(null);


    const handleObserver = useCallback((entries) => {
        const target = entries[0];
        if (target.isIntersecting) {
            setPage((prev) => prev + 1);
        }
    }, []);


    useEffect(() => {
        const option = {
            root: null,
            rootMargin: "20px",
            threshold: 0
        };
        const observer = new IntersectionObserver(handleObserver, option);
        if (loader.current) observer.observe(loader.current);
    }, [handleObserver]);

    useEffect(() => {
        SetUri(uriBuilder({ ...pagingParameter, currentPage: page }, crit));
    }, [page, pagingParameter, crit]);

    useEffect(() => {
        setPage(0);
    }, [crit]);

    var loadingPart = <></>;
    if (loading) {
        loadingPart = <ClipLoader color="#0D5C63" loading="true" size={50} />
    }

    return (
        <>
            {displayHeader()}
            <PerfectScrollbar style={{ height: "500px", overflow: "auto" }}>
                {list.map((item, i) => (
                    displayLine(item)
                ))}
                <div ref={loader} />
            </PerfectScrollbar>
            {loadingPart}
            {error && <p>Error!</p>}
        </>
    );
}


export default TableInfinite;