import { makeStyles, TextField } from "@material-ui/core";
import { Label } from "@mui/icons-material";
import { Autocomplete, Box, Button, CssBaseline, Drawer, Input, Typography } from "@mui/material";
import { textAlign } from "@mui/system";
import { useState } from "react";


function UserSearchDrawer({ showDrawer, inputCriterias, onClose, onValidate }) {
    const textFieldForDrawer = makeStyles({
        textField: {
            width: "90%",
            marginLeft: "5px",
            marginRight: "auto",
            paddingBottom: 0,
            marginTop: "20px",
            fontWeight: 500,
            "& label.Mui": {
                color: "#FFFFFF"
            },
            "& label.Mui &::placeholder": {
                color: "#FFFFFF"
            },
            "& label.Mui-focused": {
                color: "#FFFFFF"
            },
            // focused color for input with variant='standard'
            "& .MuiInput-underline:after": {
                borderBottomColor: "#FFFFFF"
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: '#FFFFFF',
                },
                '&:hover fieldset': {
                    borderColor: '#FFFFFF',
                },
                '&.Mui-focused fieldset': {
                    borderColor: '#FFFFFF',
                },
            },
        },
        input: {
            color: "#FFFFFF"
        }
    });

    const textFieldClasses = textFieldForDrawer();
    const [criterias, setCriterias] = useState(inputCriterias);
    const classes = textFieldForDrawer();
    const autoCompleteStyle = {
        width: "100%",
        marginRight: "auto",
        paddingBottom: 0,
        fontWeight: 500,
        '& input': {
            color: "white"
        },
        '& label': {
            color: "white"
        },
        "& label.Mui &::placeholder": {
            color: "#FFFFFF"
        },
        "& label.Mui-focused": {
            color: "#FFFFFF"
        },
        // focused color for input with variant='standard'
        "& .MuiInput-underline:after": {
            borderBottomColor: "#FFFFFF",
            transform: "none"
        },
        "& .MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popuIndicator": {
            color: "#FFFFFF"
        }

    };

    function getSjOptions() {
        let sjs = JSON.parse(localStorage.getItem("sjs"));
        sjs = sjs.filter(sj => sj.isOnboarded);
        const options = sjs.map((sj) => {
            return {
                "label": `(${sj.id}) ${sj.name}`,
                "id": sj.id
            };
        });
        return options;
    }
    function getSelectedSjOption() {
        const options = getSjOptions();
        var currentSj = options.find((item) => item.id == criterias.sjId);
        if (currentSj != null && currentSj != undefined)
            return {
                "label": currentSj.label,
                "id": currentSj.id
            };
        else
            return null;
    }

    return (
        <Drawer
            anchor="right"
            open={showDrawer}
            onClose={onClose}
            onClick={(e) => e.stopPropagation()}
        >
            <Box
                component="form"
                width="40vh"
                sx={{ flexGrow: 1 }}
            >
                <div style={{ width: '90%', margin: '10px' }}>
                    <Typography
                        sx={{ color: "#FFFFFF", fontWeight: "900" }}
                    >
                        Search for accounts
                    </Typography>
                </div>
                <div style={{}}>
                    <CssBaseline />
                    <TextField
                        label="Email"
                        variant="outlined"
                        value={criterias.email}
                        onChange={(e) => setCriterias({ ...criterias, freeSearch: null, email: e.target.value })}
                        className={textFieldClasses.textField}
                        InputProps={{
                            className: textFieldClasses.input
                        }}
                        InputLabelProps={{
                            className: textFieldClasses.input
                        }}
                    />
                    <TextField
                        label="LastName"
                        variant="outlined"
                        value={criterias.lastName}
                        onChange={(e) => setCriterias({ ...criterias, freeSearch: null, lastName: e.target.value })}
                        className={textFieldClasses.textField}
                        InputProps={{
                            className: textFieldClasses.input
                        }}
                        InputLabelProps={{
                            className: textFieldClasses.input
                        }}
                    />
                    <TextField
                        label="First name"
                        variant="outlined"
                        value={criterias.firstName}
                        onChange={(e) => setCriterias({ ...criterias, freeSearch: null, firstName: e.target.value })}
                        className={textFieldClasses.textField}
                        InputProps={{
                            className: textFieldClasses.input
                        }}
                        InputLabelProps={{
                            className: textFieldClasses.input
                        }}
                    />
                    <TextField
                        label="Id MDM Client"
                        variant="outlined"
                        value={criterias.clientId}
                        onChange={(e) => setCriterias({ ...criterias, freeSearch: null, clientId: e.target.value })}
                        className={textFieldClasses.textField}
                        InputProps={{
                            className: textFieldClasses.input
                        }}
                        InputLabelProps={{
                            className: textFieldClasses.input
                        }}
                    />
                    <Autocomplete
                        id="ac-sj"
                        disablePortal
                        variant="outlined"
                        options={getSjOptions()}
                        onChange={(e, value) => {
                            setCriterias({ ...criterias, freeSearch: null, sjId: value.id })
                        }}
                        defaultValue={getSelectedSjOption()}
                        sx={autoCompleteStyle}
                        renderInput={(params) => <TextField
                            variant="outlined"
                            className={classes.textField}
                            InputProps={{
                                className: classes.input
                            }}
                            InputLabelProps={{
                                className: classes.input
                            }}
                            {...params} label="Subsidiary #" />}
                    />
                </div>
                <div style={{ width: '90%', textAlign: 'center', marginTop: '40px' }}>
                    <Button
                        onClick={() => onValidate(criterias)}
                        variant='outlined'
                        sx={{ color: "white", borderColor: "white" }}
                    >
                        To validate
                    </Button>
                </div>
            </Box>
        </Drawer>
    );
}

export default (UserSearchDrawer);
