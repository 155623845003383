import { useState, useEffect, useCallback } from "react";
import { store } from '../store/rootStore';
import * as Actions from 'services/apiCaller';
import { config } from "config";

function useFetch(uri, criterias) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [list, setList] = useState([]);

    const sendQuery = useCallback(async () => {
        try {
            await setLoading(true);
            await setError(false);

            var state = store.getState();
            const token = state.authentReducer.accessToken;
            const res = await Actions.getAPI(uri);
            await setList((prev) => [...prev, ...res.data.items]);
            setLoading(false);
        } catch (err) {
            setError(err);
        }
    }, [uri]);

    useEffect(() => {
        sendQuery(uri);
    }, [uri, sendQuery]);

    useEffect(() => {
        setList([]);
    }, [criterias]);

    return { loading, error, list };
}

export default useFetch;