import { Box, Table, TableHead, TableBody, TableRow, TableContainer, TableCell, TablePagination, TableSortLabel } from "@mui/material";
import { ColumnOption } from "model/ColumnOption";
import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { TableHeaderCell } from "layouts/theme";
import useWindowDimensions from "services/windowDimensions";


function TableResult({ headers, items, pagingParam, onPageChange }) {


    var dim = useWindowDimensions();
    var maxHeight = 420;
    if (dim.height > 900)
        maxHeight = 650;


    if (items.items === null || items.items === undefined)
        return (
            <></>
        );
    else
        return (
            <Box>
                <TableContainer sx={{
                    maxHeight: {maxHeight}
                }}>
                    <Table stickyHeader size="small">
                        <TableHead>
                            <TableRow>
                                {
                                    headers.map((header) => {
                                        return (
                                            <TableHeaderCell
                                                key={header.itemProperty}
                                                sortDirection={pagingParam.isAsc ? "asc" : "desc"}
                                            >
                                                <TableSortLabel
                                                    active={pagingParam.currentSortId === header.orderField}
                                                    direction={pagingParam.isAsc ? "asc" : "desc"}
                                                    onClick={(event) => {
                                                        var isAsc = true;
                                                        if (pagingParam.currentSortId === header.orderField) {
                                                            isAsc = !pagingParam.isAsc;
                                                        }
                                                        onPageChange(event, { ...pagingParam, currentSortId: header.orderField, isAsc: isAsc });
                                                    }}
                                                >
                                                    {header.headerText}
                                                </TableSortLabel>
                                            </TableHeaderCell>
                                        );
                                    })
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                items.items.map((item) => {
                                    return (
                                        <TableRow>
                                            {
                                                headers.map((header) => {
                                                    if (header.formatHandler == null)
                                                        return (
                                                            <TableCell>{item[header.itemProperty]}</TableCell>
                                                        );
                                                    else
                                                        return (
                                                            <TableCell>{header.formatHandler(item)}</TableCell>
                                                        );
                                                })
                                            }
                                        </TableRow>
                                    );
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[pagingParam.pageSize]}
                    component="div"
                    count={items.count}
                    rowsPerPage={pagingParam.pageSize}
                    page={pagingParam.currentPage}
                    onPageChange={(event, newPage) => {
                        onPageChange(event, { ...pagingParam, currentPage: newPage });
                    }}
                    onRowsPerPageChange={(event) => {
                        onPageChange(event, { ...pagingParam, pageSize: event.target.value });
                    }}
                />
            </Box>
        );
}


const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(TableResult);