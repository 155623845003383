import { useState } from "react";
import { Alert, Snackbar } from "@mui/material";

export default function ErrorSnackBar({ message, visibility, onClose }) {

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={visibility}
            onClose={() => onClose()}>
            <Alert severity="error" sx={{ width: '250 px' }} 
                onClose={() => onClose()}
                >
                {message}
            </Alert>
        </Snackbar>
    );
}