
import { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { Add, } from "@mui/icons-material";
import 'style/CustomerPart.scss';
import { NewReferentDialog } from "components/users/newReferentDialog";

function CustomerReferentPart({ referents, customerId }) {

    const [showAddDialog, setShowAddDialog] = useState(false);

    var header = <div className="titleContainer">
        <div className="divTampon" />
        <div className="divTitle">
            Referrers
        </div>
        <div className="divTampon" />
        <div className="addButton">
            <IconButton onClick={() => setShowAddDialog(true)}>
                <Add />
            </IconButton>
        </div>
    </div>;

    function handleNewUserClose() {
        setShowAddDialog(false);
    }

    function BuildList() {
        if (referents === null
            || referents === undefined
            || referents.length === 0) {
            return (
                <div className="dataDetailContainerCenterAlign">
                    <div className="noContent">
                        No associated referrer
                    </ div>
                </div>
            );
        }
        else {
            return (
                <ul>
                    {
                        referents.map(item =>
                            <li>
                                {item.id}
                            </li>)
                    }
                </ul>
            );
        }
    }

    return (
        <div className="container">
            {header}
            <div className="dataDetailContainer">
                {BuildList()}
                <NewReferentDialog
                    open={showAddDialog}
                    handleClose={handleNewUserClose}
                    referentParam={{ clientCompanyIdString: customerId }}
                />
            </div>
        </div>
    );
}

export default CustomerReferentPart;