import { useEffect, useState, useRef, useCallback } from "react";
import * as Actions from 'services/customerApiCaller';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Box, Button, IconButton, Input, Tooltip } from "@mui/material";
import TableInfinite from "components/common/tableInfinite";
import { ExpandMore, FileDownload, FileUpload, Search } from "@mui/icons-material";
import CustomerLine from "./customerLine";
import CustomerFreeSearch from "./customerFreeSearch";
import 'style/CustomerTab.scss';

function CustomerTab({ }) {

    const [pagingParameter, setPagingParameter] = useState({
        currentPage: 0,
        pageSize: 20,
        currentSortId: 'id',
        isAsc: false
    });
    const [criterias, setCriterias] = useState({});


    const handleChange = (e) => {
        setCriterias({ freeSearch: e.target.value });
    };

    function GetHeaders() {

    }

    function BuildLine(item) {
        return (
            <CustomerLine customer={item} />
        );
    }

    function BuildHeader() {
        return (
            <div className="headerContainer">
                <div className="headerColumn" style={{ width: '3%' }}></div>
                <div className="headerColumn" style={{ width: '10%' }}>Mdm Id</div>
                <div className="headerColumn"  style={{ width: '50%' }}>Name</div>
                {/* <div className="headerColumn"  style={{ width: '10%' }}>Siret</div> */}
                <div className="headerColumn headerColumnCenter"  style={{ width: '10%' }}>is internal</div>
                <div className="headerColumn headerColumnCenter"  style={{ width: '10%' }}>Status</div>
            </div>
        );
    }

    return (
        <div>
        <Box display="flex" flexDirection="row">
            <CustomerFreeSearch onValidate={(freeSearch) => setCriterias({...criterias, freeSearch: freeSearch})} />
            <Tooltip title="Exporter" placement="top">
                <IconButton>
                    <FileDownload />
                </IconButton>
            </Tooltip>
            <Tooltip title="Importer" placement="top">
                <IconButton>
                    <FileUpload />
                </IconButton>
            </Tooltip>
            </Box>
            <TableInfinite
                crit={criterias}
                paging={pagingParameter}
                uriBuilder={Actions.getUri}
                displayHeader={BuildHeader}
                displayLine={BuildLine} />
        </div>

    );
}


export default CustomerTab;