import { FormControlLabel } from "@material-ui/core";
import { CheckBox } from "@mui/icons-material";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Autocomplete, Box, Button, CssBaseline, Typography, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import * as Actions from 'services/accountApiCaller';
import "style/Dialog.scss";

export function NewReferentDialog({ open, handleClose, referentParam }) {
    const [referent, setReferent] = useState({ referentParam });
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        setErrorMessage(null);
    }, [open]);


    useEffect(() => {
        setReferent(referentParam)
    }, [referentParam]);

    function getSjOptions() {
        var portalSjs = JSON.parse(localStorage.getItem("portalSjs"));
        return portalSjs;
    }
    function getSelectedSjOption() {
        var portalSjs = JSON.parse(localStorage.getItem("portalSjs"));
        var currentSj = portalSjs.find((item) => item.id == referent.legalCorporationId);
        if (currentSj != null && currentSj != undefined)
            return {
                "label": currentSj.label,
                "id": currentSj.id
            };
        else
            return null;
    }

    function updateReferent(e, property) {
        if (e.target !== undefined)
            referent[property] = e.target.value;
        else
            referent[property] = e;
        setReferent(referent);
    }

    function postReferent() {
        //Actions.PostPermissions(permission, handleClose, handleClose);
    }

    var errorPart = (
        <></>
    );
    if (errorMessage != null) {
        errorPart = (
            <div className="error">
                {errorMessage}
            </div>
        );
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle className="dialogTitle">Adding a referrer</DialogTitle>
            <DialogContent>
                <Box
                    component="form"
                    className="dialogcontainer"
                    sx={{ flexGrow: 1 }}
                >
                    <TextField
                        required
                        label="Email"
                        variant="outlined"
                        value={referent.userLogin}
                        onChange={(e) => updateReferent(e, "userLogin")}
                        className="textFieldStyle"
                    />
                    <Autocomplete
                        id="ac-sj"
                        disablePortal
                        variant="outlined"
                        onChange={(e, value) => {
                            updateReferent(value, "legalCorporationId")
                        }}
                        options={getSjOptions()}
                        defaultValue={getSelectedSjOption()}
                        className="autoCompleteStyle"
                        renderInput={(params) => <TextField
                            required
                            variant="outlined"
                            className="textFieldStyle"
                            {...params} label="Subsidiary #" />}
                    />
                    <TextField
                        label="Client Id"
                        variant="outlined"
                        value={referent.clientCompanyIdString}
                        onChange={(e) => updateReferent(e, "clientCompanyIdString")}
                        className="textFieldStyle"
                    />
                    <FormControlLabel 
                        value={referent.isActive}
                        control={<CheckBox />}
                        label="Asset"
                        labelPlacement="start"
                        onChange={(e) => updateReferent(e, "isActive")}
                    />
                    {errorPart}
                </Box>
            </DialogContent>
            <DialogActions>
                <div style={{ width: '100%', textAlign: 'center', marginTop: '40px' }}>
                    <Button
                        variant='outlined'
                        onClick={postReferent}
                    >
                        To validate
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
}