import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Autocomplete, Box, Button, CssBaseline, Typography, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import * as Actions from 'services/accountApiCaller';
import "style/Dialog.scss";

export function NewUserDialog({ open, handleClose, permissionParam }) {
    const [permission, setPermission] = useState({ permissionParam });
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        setErrorMessage(null);
    }, [open]);


    useEffect(() => {
        setPermission(permissionParam)
    }, [permissionParam]);

    function getSjOptions() {
        var portalSjs = JSON.parse(localStorage.getItem("portalSjs"));
        return portalSjs;
    }
    function getRoleOptions() {
        var roles = JSON.parse(localStorage.getItem("roles"));     
        
        if (permissionParam.clientId !== null
            && permissionParam.clientId !== undefined)
            roles = roles.filter((item) => item.id > 1);
        const options = roles.map((role) => {
                return {
                    "label": role.label,
                    "id": role.id
                };
        });
        return options;
    }
    function getSelectedRoleOption() {
        var roles = JSON.parse(localStorage.getItem("roles"));
        var currentRole = roles.find((item) => item.id == permission.roleId);
        if (currentRole != null && currentRole != undefined)
            return {
                "label": currentRole.label,
                "id": currentRole.id
            };
        else
            return null;
    }
    function getSelectedSjOption() {
        var portalSjs = JSON.parse(localStorage.getItem("portalSjs"));
        var currentSj = portalSjs.find((item) => item.id == permission.sjId);
        if (currentSj != null && currentSj != undefined)
            return {
                "label": currentSj.label,
                "id": currentSj.id
            };
        else
            return null;
    }

    function updatePermission(e, property) {
        if (e.target !== undefined)
            permission[property] = e.target.value;
        else
            permission[property] = e;
        setPermission(permission);
    }

    function postPermission() {
        Actions.PostPermissions(permission, handleClose, handleClose);
    }

    var errorPart = (
        <></>
    );
    if (errorMessage != null) {
        errorPart = (
            <div className="error">
                {errorMessage}
            </div>
        );
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle className="dialogTitle">Adding a user</DialogTitle>
            <DialogContent>
                <Box
                    component="form"
                    className="dialogcontainer"
                    sx={{ flexGrow: 1 }}
                >
                    <TextField
                        required
                        label="Email"
                        variant="outlined"
                        value={permission.userId}
                        onChange={(e) => updatePermission(e, "userId")}
                        className="textFieldStyle"
                    />
                    <Autocomplete
                        id="ac-sj"
                        disablePortal
                        variant="outlined"
                        onChange={(e, value) => {
                            updatePermission(value.id, "sjId")
                        }}
                        options={getSjOptions()}
                        defaultValue={getSelectedSjOption()}
                        className="autoCompleteStyle"
                        renderInput={(params) => <TextField
                            required
                            variant="outlined"
                            className="textFieldStyle"
                            {...params} label="Subsidiary #" />}
                    />
                    <Autocomplete
                        id="ac-role"
                        disablePortal
                        onChange={(e, value) => {
                            updatePermission(value.id, "roleId")
                        }}
                        options={getRoleOptions()}
                        defaultValue={getSelectedRoleOption()}
                        className="autoCompleteStyle"
                        renderInput={(params) => <TextField
                            required
                            variant="outlined"
                            className="textFieldStyle"
                            {...params} label="Role" />}
                    />
                    <TextField
                        label="Client Id"
                        variant="outlined"
                        value={permission.clientId}
                        onChange={(e) => updatePermission(e, "clientId")}
                        className="textFieldStyle"
                    />

                    {errorPart}
                </Box>
            </DialogContent>
            <DialogActions>
                <div style={{ width: '100%', textAlign: 'center', marginTop: '40px' }}>
                    <Button
                        variant='outlined'
                        onClick={postPermission}
                    >
                        To validate
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
}