import {store} from '../store/rootStore';
const axios = require('axios');

export async function getAxiosrequest(url){
    var state = store.getState();
    const token = state.authentReducer.accessToken;  
    return axios.get(url, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    });
}

export async function getAPI(url) {
    var state = store.getState();
    const token = state.authentReducer.accessToken;
    return axios.get(url, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    }).then((response) => {
        if (response.status !== 200) {
            console.error(response.statusText);
            return response.status;
        } else {
            return response;
        }
    })
    .catch((error) => {
        console.error(error);
    });
}


export async function downloadAPI(url) {
    var state = store.getState();
    const token = state.authentReducer.accessToken;
    return axios.get(url, {
        headers: {
            Authorization: 'Bearer ' + token
        },
        responseType: 'arraybuffer'
    }).then((response) => {
        if (response.status !== 200) {
            return response.status;
        } else {
            return response;
        }
    })
    .catch((error) => {
        console.error(error);
    });
}

export async function postAPI(url, json) {
    var state = store.getState();
    const token = state.authentReducer.accessToken;

    return axios.post(url, json, {
        headers: { Authorization: 'Bearer ' + token, 'Content-Type': 'application/json', 'accept': '*/*'}
    }).then((response) => {
        if (response.status !== 200) {
            console.error(response.statusText);
            return response.status;
        } else { return response; }
    })
}

export async function postFileAPI(url, file) {
    var state = store.getState();
    const token = state.authentReducer.accessToken;

    const formData = new FormData();
    
    // Update the formData object
    formData.append("formFile",file);
    
    return axios.post(url, formData, {
        headers: 
        { 
            Authorization: 'Bearer ' + token, 
            'Content-Type': 'multipart/form-data', 
            'accept': '*/*'}
    }).then((response) => {
        if (response.status !== 200) {
            console.error(response.statusText);
            return response.status;
        } else { return response; }
    })
}