import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { Link } from "@reach/router";
import { AppBar, Badge, Box, CssBaseline, IconButton, Menu, MenuItem, Toolbar, Typography } from "@mui/material";
import { config } from "config";


const isActive = ({ isCurrent }: any) => {
    return isCurrent
        ? { className: "active nav-link" }
        : { className: "nav-link" };
};
// `getProps` Calls up to you to get props for the underlying anchor element.
// Useful for styling the anchor as active.
const ExactNavLink = (props: any) => <Link getProps={isActive} {...props} />;

function NavBar({ userName }: any) {
    const [auth, setAuth] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleChange = (event: any) => {
        setAuth(event.target.checked);
    };

    const handleMenu = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    var appEnv = (
        <></>
    );
    if (config.REACT_APP_ENV !== "prod") {
        appEnv = (
            <>
                &nbsp;({config.REACT_APP_ENV})
            </>
        );
    }

    return (
        <Box sx={{backgroundColor:"#FFFFFA"}}>
            <Toolbar>
                <Typography
                    variant="h6"
                    noWrap
                    component="div"
                    align="center"
                    sx={{ display: { xs: 'none', sm: 'block' } }}
                >
                    {config.REACT_APP_NAME}
                    {appEnv}
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <Typography>
                    {userName}
                </Typography>
                <Typography
                    noWrap
                    component="div"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    sx={{ display: { xs: 'none', sm: 'block' }, cursor: 'pointer' }}
                    onClick={handleMenu}>
                </Typography>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={open}
                    onClose={handleClose}
                >
                </Menu>
            </Toolbar>
        </Box>

    );
}

const mapStateToProps = (state: any) => {
    return {
        userName: state.authentReducer.name,
    };
};

export default connect(mapStateToProps)(NavBar);
