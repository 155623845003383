import * as ApiActions from "./apiCaller";
import { config } from "config";
import axios from "axios";

export async function GetAllCustomerLinkedItems(customerId) {
    var baseUrlForAccounts = config.REACT_APP_BASE_API_URL + "Users?clientId=" + customerId;
    var baseUrlForReferents = config.REACT_APP_BASE_API_URL + "Referents?customerMdmId=" + customerId;

    var accounts = [];
    var referents = [];
    var errors = null;

    try {
        var responses = await axios.all([ApiActions.getAxiosrequest(baseUrlForAccounts),
        ApiActions.getAxiosrequest(baseUrlForReferents)]).then(axios.spread((...responses) => {
            return responses;
        }));

        accounts = responses[0].data.items;
        referents = responses[1].data.items;
    }
    catch (err) {
        errors = err;
    }

    return { accounts, referents, errors };
} 


export function getUri(pagingParameter, searchCriterias, count)
{
    var isAsc = "true";
    if (!pagingParameter.isAsc)
        isAsc = "false";
    var baseUrl = config.REACT_APP_BASE_API_URL
        + 'Customers?StartPage=' + pagingParameter.currentPage
        + '&Count=' + pagingParameter.pageSize
        + '&OrderField=' + pagingParameter.currentSortId
        + '&IsAsc=' + pagingParameter.isAsc;
    if (searchCriterias !== null) {
        if (searchCriterias.freeSearch != null
            && searchCriterias.freeSearch != undefined)
            baseUrl += '&freeSearch=' + searchCriterias.freeSearch;
    }
    return baseUrl;
}

export async function UpdateCustomer(customer, okHandler, koHandler){
    
    var baseUrl = config.REACT_APP_BASE_API_URL + 'Customers';
    try{
        var response = await ApiActions.postAPI(baseUrl, JSON.stringify(customer));
        if(okHandler !== null 
            && okHandler !== undefined)
            okHandler();
    }
    catch(ex)
    {
        if(koHandler !== null 
            && koHandler !== undefined)
            koHandler();
    }
}