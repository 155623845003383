import { Search } from "@mui/icons-material";
import { Icon, IconButton, Input, Tooltip } from "@mui/material";
import { useState } from "react";

function CustomerFreeSearch({onValidate})
{
    const[freeSearch, setFreeSearch] = useState(null);

    function submitForm(e)
    {
        e.preventDefault();
        onValidate(freeSearch);
    }

    return(
        <form onSubmit={(e)=>submitForm(e)}>
            <Input type="text" value={freeSearch} onChange={(e)=>setFreeSearch(e.target.value)}/>
            <Tooltip title="Free search" placement="top">
                <IconButton
                    type="submit"
                    onClick={submitForm}>
                    <Search />
                </IconButton>
            </Tooltip>
        </form>
    );
}

export default CustomerFreeSearch;