import { AppBar, Badge, Box, Card, CardContent, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Toolbar, Typography, useTheme } from "@mui/material";
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MenuIcon from '@mui/icons-material/Menu';
import CssBaseline from '@mui/material/CssBaseline';
import AccountCircle from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NavBar from './navBar'
import SideBar from "./sideBar";
import useWindowDimensions from "services/windowDimensions";

type Props = {
    children: any,
}
const drawerWidth = 150;
const styles = {

    largeIcon: {
        width: 60,
        height: 60,
    },

};


export default function MainLayout({ children }: Props) {
    const theme = useTheme();
    const { height, width } = useWindowDimensions();

    
    return (
        <Box height="100vh" sx={{ display: 'flex' }}>
            <CssBaseline />
            <SideBar />
            <Box component="main" height="100vh" sx={{
                flexGrow: 1,
                p: 3, backgroundColor: 'rgba(68,161,160, 0.1)',
                padding: 0
            }}>
                <NavBar />
                {children}
            </Box>
        </Box>
    );
}