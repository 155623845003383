import { makeStyles, TextField } from "@material-ui/core";
import { Label } from "@mui/icons-material";
import { Box, Button, CssBaseline, Drawer, Input, Typography } from "@mui/material";
import { textAlign } from "@mui/system";
import { useState } from "react";
import { textFieldForDrawer } from "layouts/theme";
import { FileUploader } from "react-drag-drop-files";
import 'layouts/custom.css'

const fileTypes = ["csv"];

function UserImportDrawer({ showDrawer, onClose, onValidate }) {
    const [files, setFiles] = useState([]);
    
    function handleChange(file){
        setFiles([...files, file]);
    };
    const classes = textFieldForDrawer();

    function showFiles() {
        if (files.length == 0)
            return (
                <></>
            );
        else
            return (
                <ul style={{color:"#FFFFFF"}}>
                    {
                        files.map((file) => <li style={{color:"#FFFFFF"}}>{file.name}</li>)
                    }
                </ul>
            );
    }

    function launchUpload(){
        if(files.length > 0)
        {
            onValidate(files[0]);
        }
    }

    return (
        <Drawer
            anchor="right"
            open={showDrawer}
            onClose={onClose}
            onClick={(e) => e.stopPropagation()}
        >
            <Box
                component="form"
                width="50vh"
                sx={{ flexGrow: 1 }}
            >
                <div style={{ width: '90%', margin: '10px' }}>
                    <Typography
                        sx={{ color: "#FFFFFF", fontWeight: "900" }}
                    >
                        Import Accounts
                    </Typography>
                </div>
                <div style={{ width: '20%', margin: '10px' }}>
                    <CssBaseline />
                    <FileUploader classes="drop_zone"  handleChange={handleChange} name="file" types={fileTypes} />
                </div>
                <div>
                    {showFiles()}
                </div>
                <div style={{ width: '90%', textAlign: 'center', marginTop: '40px' }}>
                    <Button
                        variant='outlined'
                        sx={{ color: "white", borderColor: "white" }}
                        onClick={launchUpload}
                    >
                        To validate
                    </Button>
                </div>
            </Box>
        </Drawer>
    );
}

export default (UserImportDrawer);
