import { TabPanel } from "components/common/tabPanel";
import SiteActivityTab from "components/sites/siteActivityTab";
import SiteTab from "components/sites/siteTab";
import CustomerTab from "components/customers/customerTab";
import UserTab from "components/users/userTab";
import MainLayout from "layouts/mainLayout";
import { Box, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import * as Actions from "../store/actions/userActions";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function Users() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <MainLayout>
        <Box>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Permissions" {...a11yProps(0)} />
            {/* <Tab label="Customers" {...a11yProps(1)} /> */}
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <UserTab />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <CustomerTab />
        </TabPanel>
      </MainLayout>
    </>
  );
}


const mapStateToProps = (state: any) => {
  return {
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);