import { Alert, Box, Button, Chip, IconButton, Input, Snackbar, Tooltip } from "@mui/material";
import { Check, ExpandLess, ExpandMore, FileDownload, FileUpload, RemoveCircleOutline, RemoveCircleOutlineOutlined, Search } from "@mui/icons-material";
import { useState } from "react";
import { ClipLoader } from "react-spinners";
import CustomerAccountPart from "./customerAccountPart";
import CustomerReferentPart from "./customerReferentPart";
import * as Actions from 'services/customerApiCaller';
import ErrorSnackBar from "components/common/messageSnackBars";
import 'style/infiniteLine.scss';
import 'style/CustomerLine.scss';

function CustomerLine({ customer }) {
    const [expandState, setExpandState] = useState(
        {
            isExpand: false,
            isDetailLoaded: false,
            isDetailLoading: true,
            accounts: [],
            referents: [],
            error: null,
            isErrorSnackBarOpen: false,
        });
    const [currentCustomer, setCurrentCustomer] = useState(customer);

    const logo = <img src="/images/colas_logo.png" style={{ width: '50px' }} />
    const activeLogo = (
        <IconButton sx={{ padding: 0 }}>
            <Check sx={{ fontSize: 16 }} />
        </IconButton>
    );
    const inactiveLogo = (
        <IconButton sx={{ padding: 0 }}>
            <RemoveCircleOutline sx={{ fontSize: 16 }} />
        </IconButton>
    );

    var messageIsExpand = "More details";
    if (expandState.isExpand) {
        messageIsExpand = "Fewer details";
    }

    function ManageUpdateStatus(newStatus) {
        var customerUpdated = { ...currentCustomer, isActive: newStatus };
        Actions.UpdateCustomer(customerUpdated, () => {
            setCurrentCustomer({ ...currentCustomer, isActive: newStatus });
        },
            () => {
                alert('ko');
            })
    }

    function ManageUpdateInternal(newInternalStatus) {
        var customerUpdated = { ...currentCustomer, isInternal: newInternalStatus };
        Actions.UpdateCustomer(customerUpdated, () => {
            setCurrentCustomer({ ...currentCustomer, isInternal: newInternalStatus });
        },
            () => {
                alert('ko');
            })
    }

    function buildDetail() {
        var detail = <></>;
        var errorSnackBar = <></>;
        if (expandState.isErrorSnackBarOpen && expandState.error != null) {
            errorSnackBar = <ErrorSnackBar
                message={expandState.error.response.data.detail}
                visibility={expandState.isErrorSnackBarOpen}
                onClose={() => setExpandState({ ...expandState, isErrorSnackBarOpen: false })}
            />;
        }
        if (expandState.isExpand) {
            if (expandState.isDetailLoading) {
                detail = <>
                    <div className="detailSpinnerLoader">
                        <ClipLoader color="#0D5C63" loading="true" size={50} />
                    </div>
                    {errorSnackBar}
                </>;
            }
            else {
                detail = <>
                    <div className="detailContainer">
                        <div className="accountContainer">
                            <CustomerAccountPart accounts={expandState.accounts} customerId={currentCustomer.id} />
                        </div>
                        <div className="referentContainer">
                            <CustomerReferentPart referents={expandState.referents} customerId={currentCustomer.id} />
                        </div>
                    </div>
                    {errorSnackBar}
                </>;
            }
        }
        return detail;
    }

    function ExpandManagement() {
        var isDetailLoading = true;
        if (expandState.isDetailLoaded) {
            isDetailLoading = false;
        }

        if (isDetailLoading) {
            Actions.GetAllCustomerLinkedItems(currentCustomer.id).then((response) => {
                setExpandState({
                    ...expandState,
                    isExpand: true,
                    isDetailLoading: false,
                    isDetailLoaded: true,
                    accounts: response.accounts,
                    referents: response.referents,
                    errorHasBeenDisplayed: false,
                    error: response.errors,
                    isErrorSnackBarOpen: response.errors != null
                });
            });
        }

        setExpandState({ ...expandState, isDetailLoading: isDetailLoading, isExpand: !expandState.isExpand });
    }

    function InternalChip() {
        return (
            <Tooltip title="Cliquer pour rendre le client externe" placement="top">
                <Chip id={"intern" + currentCustomer.id}
                    label="interne"
                    icon={<Check />}
                    onClick={() => ManageUpdateInternal(false)} color="warning" variant="outlined" />
            </Tooltip>
        );
    }

    function ExternalChip() {
        return (
            <Tooltip title="Click to make internal client" placement="top">
                <Chip id={"ext" + currentCustomer.id}
                    label="external"
                    icon={<Check />}
                    onClick={() => ManageUpdateInternal(true)} color="info" variant="outlined" />
            </Tooltip>
        );
    }


    function ActiveChip() {
        return (
            <Tooltip title="Click to make the client active" placement="top">
                <Chip id={"asset" + currentCustomer.id}
                    label="asset" icon={<Check />}
                    onClick={() => ManageUpdateStatus(false)}
                    color="success" variant="outlined" />
            </Tooltip>
        );
    }


    function InactiveChip() {
        return (
            <Tooltip title="Click to make the client active" placement="top">
                <Chip id={"asset" + currentCustomer.id}
                    label="asset" icon={<RemoveCircleOutline />}
                    onClick={() => ManageUpdateStatus(true)}
                    color="error" variant="outlined" />
            </Tooltip>
        );
    }

    function BuildDataLine() {
        return (
            <div className="mainDataContainer">
                <div className="expandPart">
                    <Tooltip title={messageIsExpand} placement="top">
                        <IconButton id={"exp" + currentCustomer.id} onClick={ExpandManagement}>
                            {!expandState.isExpand && <ExpandMore />}
                            {expandState.isExpand && <ExpandLess />}
                        </IconButton>
                    </Tooltip>
                </div>
                <div className="dataColumn" style={{ width: '10%' }}>{currentCustomer.id}</div>
                <div className="dataColumn" style={{ width: '50%' }}>{currentCustomer.name}</div>
                {/* <div className="dataColumn" style={{ width: '10%' }}>{currentCustomer.siret}</div> */}
                <div className="dataColumn dataColumnCenter" style={{ width: '10%' }}>
                    {currentCustomer.isInternal && InternalChip()}
                    {!currentCustomer.isInternal && ExternalChip()}
                </div>
                <div className="dataColumn dataColumnCenter" style={{ width: '10%' }}>
                    {currentCustomer.isActive && ActiveChip()}
                    {!currentCustomer.isActive && InactiveChip()}
                </div>
            </div>
        );
    }


    return (
        <div className="mainContainer">
            {BuildDataLine()}
            {buildDetail()}
        </div>
    );
}

export default CustomerLine;